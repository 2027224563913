export const Authendpoint = {
  Login: "admin/login",
  logout: "admin/log-out",
};
export const Globalendpoints = {
  dashboardCount: "admin/fetch-dashboard-count",
  graphData: "admin/fetch-graph-data",
  createGame: "admin/create-game",
  getGameList: "admin/fetch-games",
  updateGame: "admin/update-games/",
  addBankDetail: "admin/add-bank-details",
  updateBankDetail: "admin/update-bank-details",
  fetchBankDetail: "admin/fetch-bank-details",
  getPaymentSetting: "admin/get-payment-setting",
  updatePaymentSetting: "admin/update-payment-setting",
  fetchFundReq: "admin/fetch-fund-request",
  fundReqAction: "admin/update-fund-request",
  fetchWithdrawalReq: "admin/fetch-withdrawal",
  updateWithdrawalReq: "admin/update-withdraw-request",
  currentRoomStats: "",
  roomDetails: "",
  chooseWinner: "",
  betDetailById: "",

  //aviator game

  aviatorSetting: "aviator/admin/fetch-settings",
  aviatorPlanHandler: "aviator/admin/plane-handler",
  aviatorRooms: "aviator/admin/fetch-aviator-room-records",
  aviatorBettingRecord: "aviator/admin/fetchBettingRecords",
  aviatorSetRange: "aviator/admin/set-plane-range-manually",
  aviatorCrashPlane: "aviator/admin/crashPlane",
  aviatorCurrentPlane: "aviator/admin/fetch-current-plane",

  //mines game

  mineSettings: "mines/admin/game-setting",
  minesUpdateSetting: "mines/admin/update-game-setting",
  minesBettingRecord: "mines/admin/fetch-betting-records",
  minesLatestBettingRecord: "mines/admin/fetch-latest-betting-records",

  //plinko games

  plinkoSettings: "plinko/admin/game-setting",
  plinkoUpdateSetting: "plinko/admin/update-game-setting",
  plinkoBettingRecord: "plinko/admin/fetch-betting-records",
  plinkoLatesyBettingRecord: "plinko/admin/fetch-latest-betting-records",

  userList: "admin/fetch-user-list",
  updateUser: "admin/update-user-status",
  userBettings: "admin/fetch-user-bettings",
  walletTxn: "admin/fetch-user-wallet-tranx",
  colorOption: "",
  changePassword: "admin/change-password",

  //comissionSetting
  fetchComissionSetting: "admin/fetch-commission-setting",
  updateReferalComm:"admin/update-referal-setting"
};
