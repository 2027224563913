import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";
import { Skeleton } from "antd";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };


  return isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
      <Suspense
             fallback={
              <>
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
              </>
            }
            >
          <Outlet />
          </Suspense>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
