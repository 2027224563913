import axios from "axios";
import { dispatchtoast, removeLocalStorageItem, showError } from "../../Utils";

// const cookies = new Cookies();
// const jwtToken = cookies.get("jwt_token");
const version = "/api/v1/";
// export const APIURL = `http://192.168.1.37:11005`;
export const APIURL = `https://ezulix-games.ezulix.co`;

const combine = `${APIURL}${version}`;
// export const APIURL="http://192.168.1.145/b2b_admin/"

const ApiService = axios.create({
  withCredentials: true,
  baseURL: combine,
});

// Add a request interceptor
ApiService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  // config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
ApiService.interceptors.response.use(
  (response) => {
    if (response.data.status) {
      return response.data;
    } else {
      if (response.data.formErrors) {
        showError(response.data.formErrors);
      } else if (response.data.validationError) {
        showError(response.data.validationError);
      } else {
        dispatchtoast(response.data.message ?? response.data.error, true);
      }
      return response.data;
    }
  },
  (error) => {
    // Handle errors if needed
    const { status } = error?.response;
    console.log(error);
    if (status === 401) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
      removeLocalStorageItem("isLoggedIn");
      removeLocalStorageItem("accessToken");
      window.location.href = "/";
    }
    if (status && status >= 400 && status < 500) {
      if (error.response.data.error) {
        showError(error.response.data.error);
      } else if (error.response.data.formErrors) {
        showError(error.response.data.formErrors);
      } else {
        dispatchtoast(
          error?.response.data.message ?? error?.response.data.message,
          true
        );
      }
    } else if (status && status >= 500) {
      if (error.response.data.error) {
        showError(error.response.data.error);
      } else if (error.response.data.formErrors) {
        showError(error.response.data.formErrors);
      } else {
        dispatchtoast(
          error?.response.data.message ?? error?.response.data.message,
          true
        );
      }
    }
    return Promise.reject(error);
  }
);

export default ApiService;
