import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FaUnlockAlt } from "react-icons/fa";
import { userLogin } from "../../ApiServices/Apifun";
import { setLocalStorageItem } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { Images } from "../../Controller";
// import { Editor } from "@tinymce/tinymce-react";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate();
  const handleSubmit = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken", res.access_token);
          setLocalStorageItem("refreshToken", res.referesh_token);
          setLocalStorageItem("userDetails", res.user);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000);
    return ()=>clearInterval(timer)
  }, []);
  return (
    <>
      {showSplash ? (
        <div className="flex  justify-center bg-darkbg opacity-95 items-center h-screen">
          <img alt="logo" src={Images.WebsiteLogo} className="w-52 animate-zoomInOut" />
        </div>
      ) : (
        <div className="flex loginbg bg-primary opacity-90 font-inter h-screen justify-center items-center">
          <div className="w-[500px]">
            <div className="bg-black h-[100px] flex justify-around items-center text-white">
              <p>
                <FaUnlockAlt size={30} />
              </p>
              <p className="text-lg font-bold">Login</p>
            </div>
            <div className="p-4 bg-darkbg2">
              <Form
                onFinishFailed={(err) => console.log(err)}
                onFinish={(val) => handleSubmit(val)}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter Email I'd",
                    },
                  ]}
                  className="w-full"
                  name={"email"}
                >
                  <Input className="mt-2" placeholder="Enter Email" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                  name={"password"}
                >
                  <Input className="mt-2" placeholder="Enter password" />
                </Form.Item>
                <Form.Item className="w-full" name={"password"}>
                  <Button
                    loading={btnLoading}
                    style={{ color: "white" }}
                    className="w-full mt-4 hover:border-none focus:border-none bg-primaryhover"
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
