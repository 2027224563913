import {  HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg } from "./Utils";
function App() {
  const RoulleteRouting = [...PublicRoutes(), ...PrivateAuth()];
  const RoulleteRouteConfig = () => useRoutes(RoulleteRouting);

  return (
    <>
    {/* <Login/> */}
     <div className="font-sans bg-darkMainbg">
     <HashRouter >
          <RoulleteRouteConfig />
      </HashRouter>
     </div>
    <ToastContainerMsg />
      
    </>
  );
}

export default App;
